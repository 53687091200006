import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import MainLayout from './layouts/Main';
import AgencyHome from './pages/AgencyHome';
import AltHomePage from './pages/AltHome';
import AuthVerifierPage from './pages/AuthVerifier';
import BillingPage from './pages/Billing';
import AdminHomePage from './pages/ColabAdminPages/AdminHomePage';
import ForgotPasswordPage from './pages/ForgotPassword';
import GenericNotFoundPage from './pages/GenericNotFound';
import HomePage from './pages/Home';
import LogInPage from './pages/LogIn';
import PrivacyPolicyPage from './pages/PrivacyPolicy';
import ProfilePage from './pages/Profile';
import SignUpPage from './pages/SignUp';
import StripeCheckoutPage from './pages/StripeCheckoutPage';
import TeamPage from './pages/Team';
import UnsubscribePage from './pages/UnsubscribePage';
import UpdatePasswordPage from './pages/UpdatePassword';
import permissionBlocks from './permissionBlocks';
import HomeRedirectRoute from './utils/HomeRedirectRoute';
import PrivateRoute from './utils/PrivateRoute';

const Routes = () => {
  const user = useSelector((state) => state.user);
  const isSuperAdmin = user?.organization?.type === 'superadmin';
  const isAgency = user?.organization?.type === 'agency';
  const onlyHasVideoBundles =
    user?.orders.length >= 1 &&
    user.orders.every((order) =>
      order.order_items.some((item) => item.product_name === 'video_bundle')
    );
  const { pathname } = useLocation();
  const pages = Object.keys(permissionBlocks)
    .map((blockKey) =>
      Object.keys(permissionBlocks[blockKey].pages).map((key) => ({
        ...permissionBlocks[blockKey].pages[key],
        pagePath: key,
        blockPath: permissionBlocks[blockKey].genericPath,
        hasAccess:
          isSuperAdmin ||
          isAgency ||
          permissionBlocks[blockKey].getPaths(user)?.length > 0,
      }))
    )
    .flat();

  return (
    <Switch>
      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
      <Redirect exact from="/" to="/home" />
      <PrivateRoute exact path="/home">
        {(() => {
          if (isAgency || isSuperAdmin) return () => <AgencyHome />;
          if (onlyHasVideoBundles) return () => <AltHomePage />;
          return HomePage;
        })()}
      </PrivateRoute>
      <PrivateRoute path="/orders/:orderId/home">
        {(() => {
          if (isSuperAdmin) return () => <AdminHomePage />;
          if (user) return () => <Redirect to="/home" />;
          return MainLayout;
        })()}
      </PrivateRoute>
      {pages.map((page) => {
        return (
          <PrivateRoute
            exact
            path={`${page.blockPath}/${page.pagePath}${page.innerPath || ''}`}
            component={(() => {
              if (!user) return MainLayout;
              if (page.hasAccess) return page.component;
              return () => <Redirect to="/home" />;
            })()}
          />
        );
      })}
      <PrivateRoute exact path="/stripe-checkout">
        {(() => {
          if (user?.organization?.onboarding_step >= 3)
            return () => <StripeCheckoutPage />;
          return MainLayout;
        })()}
      </PrivateRoute>
      <Redirect exact from="/settings" to="/settings/profile" />
      <PrivateRoute exact path="/settings/profile" component={ProfilePage} />
      <PrivateRoute exact path="/settings/team" component={TeamPage} />
      <PrivateRoute exact path="/settings/billing" component={BillingPage} />
      <HomeRedirectRoute path="/login" component={LogInPage} />
      {/* <HomeRedirectRoute path="/signup" component={SignUpPage} /> */}
      <Route exact path="/authVerifier" component={AuthVerifierPage} />
      <Route path="/privacy" component={PrivacyPolicyPage} />
      <Route path="/forgot-password/:status?" component={ForgotPasswordPage} />
      <Route
        exact
        path="/reset/:userId/:token"
        component={UpdatePasswordPage}
      />
      <Route exact path="/unsubscribe/:id" component={UnsubscribePage} />
      <Route component={GenericNotFoundPage} />
    </Switch>
  );
};

export default Routes;
