import { Button, Checkbox, Textfield } from 'colabs-ui-react';
import React, { useState } from 'react';
import { Eye, EyeOff, Loader } from 'react-feather';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { fetchUserData } from '../actions';
import CoEfficientLabs from '../assets/icons/CoefficientLabs';
import LinearGradient from '../components/presentational/LinearGradient';
import StartLayout from '../layouts/Start';
import { api } from '../utils/useAxioswithAuth';

const StyledLogInPage = styled.div`
  text-align: center;
  width: 100%;
  > svg {
    height: 2rem;
    text-align: center;
    .logo {
      fill: url(#linearGradient);
    }
  }
  > .title {
    font-family: ${({ theme }) => theme.font.main.medium};
    font-size: 1.5rem;
    letter-spacing: -0.05rem;
    text-align: center;
    margin-bottom: 2rem;
    > .colabs-name {
      color: #1171ff;
    }
  }
  > img {
    display: none;
    @media ${({ theme }) => theme.breakpoint.md} {
      display: inherit;
      width: 100%;
      border-radius: 2rem;
      margin: 0.25rem 0 2rem 0;
    }
  }
  > .socialLogin {
    width: 100%;
    margin-bottom: 1rem;
    > * {
      margin-bottom: 0.75rem;
    }
  }
  > form {
    display: grid;
    grid-gap: 1rem;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    margin-top: 1rem;
    margin-bottom: 2rem;
    > .email,
    > .password {
      text-align: left;
      grid-column: 1 / 3;
    }
    > .password {
      margin-bottom: 1rem;
    }
  }
  .link {
    color: #1171ff;
    cursor: pointer;
    &:hover {
      color: #48bfff;
    }
  }
  > .signup {
    margin-top: 0.5rem;
    display: block;
    white-space: nowrap;
  }
`;

const LogInPage = () => {
  const [passwordShown, setPasswordShown] = useState(false);
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    email: '',
    password: '',
    rememberMe: false,
  });
  const [fetchState, setFetchState] = useState('start');

  const handleSubmit = async (e) => {
    setFetchState('loading');
    e.preventDefault();
    try {
      await api.post(`/auth/login`, {
        email: form.email.toLowerCase().trim(),
        password: form.password.trim(),
        remember_me: form.rememberMe,
      });
      setFetchState('success');
      dispatch(fetchUserData());
    } catch (err) {
      setFetchState('error');
      if (err.response.status === 429) {
        alert('Too many requests, please try again in a few minutes');
      }
      if (err.response.status === 401 || err.response.status === 422) {
        alert('Invalid email or password');
      }
      if (err.response.status === 500) {
        alert('Server Error. Please contact support');
      }
    }
  };

  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };

  const handleChange = (e) => {
    e.preventDefault();
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <StartLayout>
      <StyledLogInPage>
        <LinearGradient color1="#1171FF" color2="#61E3FF" id="linearGradient" />
        <CoEfficientLabs />
        <h1 className="title">
          Welcome to <span className="colabs-name">CoEfficient Labs</span>!
        </h1>
        <form
          name="logIn"
          onSubmit={handleSubmit}
          data-fetch-state={fetchState.state}
        >
          <Textfield
            autoComplete
            placeholder="jackson@coefficientlabs.com"
            type="email"
            name="email"
            className="email"
            label="Email:"
            required
            // minLength={10}
            // maxLength={50}
            onChange={handleChange}
          />
          <Textfield
            placeholder="password"
            icon={
              passwordShown ? (
                <EyeOff onClick={togglePasswordVisiblity} />
              ) : (
                <Eye onClick={togglePasswordVisiblity} />
              )
            }
            name="password"
            className="password"
            onChange={handleChange}
            iconPos="right"
            type={passwordShown ? 'text' : 'password'}
            label="Password:"
            required
            // minLength={6}
            // maxLength={18}
          />
          <Checkbox
            label="Remember me"
            checked={form.rememberMe}
            onChange={(event) => {
              setForm({ ...form, rememberMe: event.target.checked });
            }}
          />
          <Button
            onClick={handleSubmit}
            fullWidth
            {...(fetchState.state === 'loading'
              ? {
                  text: '',
                  disabled: true,
                  icon: (
                    <Loader
                      type="ThreeDots"
                      // color="#ffffff"
                      height={30}
                      width={30}
                    />
                  ),
                }
              : {
                  text: 'Log In',
                  disabled: false,
                })}
          />
        </form>
        <Link to="/forgot-password" className="link">
          <span className="forgotPassword">Forgot your password?</span>
        </Link>
        {/* <span className="signup">
          Don't have an account?{' '}
          <Link to="/signup" className="link">
            Sign Up
          </Link>
        </span> */}
      </StyledLogInPage>
    </StartLayout>
  );
};

export default LogInPage;
